body{
    background-color: rgb(35,37,46);
    color:white;
    font-family: Inter;
    width: 50%;
    margin: auto;
}

.header {
    text-align: center;
}

.header-pic {
    width: 350px;
}

.header-job-title, .header-dynamic-website {
    color:rgb(150,150,150)
}

.btn-linkedin {
    background: #0E76A8;
    color: #fff;
    border: none;
    margin-left: 50;
    padding: 7 25;
    border-radius: 5px;
    vertical-align: auto;
}
.btn-linkedin:link, .btn-linkedin:visited {
    color: #fff;
    
}
.btn-linkedin:active, .btn-linkedin:hover {
    background: #084461;
    color: #fff;
}

.header-button-email {
    padding: 7 25;
    border-radius: 5px;
    font-family: Inter;
    vertical-align: auto;
    font-size: 17;
}

.header-button-email:hover {
    background:rgb(120,120,120);
    color: white;
}
      
.header-buttons {
    display: flex;
    justify-content: center;
    vertical-align: auto;
}      

.maincontent {
    padding-left: 20;
    padding-right: 20;
    width: 50%;
    margin: auto;
}

.maincontent-title {
    color: #fff;
}

.maincontent-text {
    color: rgb(150,150,150)
}

.footer {
    color: rgb(150,150,150);
    text-align: center;
}

.footer-button {
    padding: 10 20;
}